<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3 pa-6">
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <h2>
          Stock Count
        </h2>
      </v-col>
      <v-col
        cols="12"
        xs="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <v-select
          v-model="query.state"
          :items="statusItems"
          item-value="value"
          item-text="name"
          dense
          label="Status"
          outlined
          placeholder="Status"
        >
        </v-select>
      </v-col>
      <v-col
        xs="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <v-text-field
          v-model="query.search"
          dense
          outlined
          label="Search"
          append-icon="mdi-magnify"
          placeholder="Search : ( Warehouse, ID )"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="elevation-1"
          :headers="stockCountHeaders"
          :items="stockCountList"
          :loading="loading"
          hide-default-footer
        >
          <template #[`item.state`]="{ item }">
            <span :class="`${stateData[item.state].color}--text`">{{ stateData[item.state].name }}</span>
          </template>
          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateTimeFormat() }}
          </template>
          <template #[`item.stockBc`]="{ item }">
            {{ item.stockBc | showNumberFormat() }} ({{ item.stockBcValue | showNumberFormat() }})
          </template>
          <template #[`item.stockCount`]="{ item }">
            {{ item.stockCount | showNumberFormat() }} ({{ item.stockCountValue | showNumberFormat() }})
          </template>
          <template #[`item.warehouses`]="{ item }">
            {{ item.warehouses | stockWarehouse() }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              small
              color="primary"
              :to="{ name: 'StockCountDetail', params: { stockCountId: item.id } }">
              view
            </v-btn>
          </template>
        </v-data-table>
        <v-pagination
          v-model="query.page"
          class="mt-8"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import NewStockCountProvider from '@/resources/NewStockCountProvider'

const newStockCountService = new NewStockCountProvider()

export default {
  filters: {
    stockWarehouse (warehouses) {
      return warehouses.reduce((acc, item) => (acc ? `${acc}, ${item.name}` : item.name), '')
    }
  },
  data () {
    return {
      loading: false,
      stockCountHeaders: [
        {
          text: 'Code',
          align: 'start',
          sortable: false,
          value: 'code'
        },
        {
          text: 'Status',
          align: 'center',
          sortable: false,
          value: 'state'
        },
        {
          text: 'Create Time',
          align: 'center',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: 'Bc Stock',
          align: 'right',
          sortable: true,
          value: 'stockBc'
        },
        {
          text: 'Stock Count',
          align: 'right',
          sortable: true,
          value: 'stockCount'
        },
        {
          text: 'Warehouse / Location',
          align: 'center',
          sortable: false,
          value: 'warehouses'
        },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      stateData: {
        all: {
          name: 'ทั้่งหมด',
          value: ''
        },
        pending: {
          name: 'รอนับ',
          value: 'pending',
          color: 'red'
        },
        processing: {
          name: 'กำลังนับ',
          value: 'processing',
          color: 'indigo'
        },
        inspecting: {
          name: 'รอตรวจสอบ',
          value: 'inspecting',
          color: 'orange'
        },
        completed: {
          name: 'สำเร็จ',
          value: 'completed',
          color: 'green'
        }
      },
      stockCountList: [],
      query: {
        state: '',
        page: 1,
        limit: 10,
        search: '',
        warehouseIds: []
      },
      pageCount: 0
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    statusItems () {
      return Object.keys(this.stateData).map((key) => this.stateData[key])
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getStockCountList()
      },
      deep: true
    },
    'query.state': {
      handler () {
        this.getStockCountList()
      },
      deep: true
    },
    'query.search': {
      handler () {
        clearTimeout(this.delayVendorSearch)
        this.delayVendorSearch = setTimeout(() => {
          this.getStockCountList()
        }, 300)
      },
      deep: true
    }
  },
  async mounted () {
    await this.getStockCountList()
  },
  methods: {
    async getStockCountList () {
      this.query.warehouseIds = this.mapWarehouse.map((wh) => wh.id)
      const { data } = await newStockCountService.getAll(this.query)
      if (!data) return
      this.pageCount = data.pages
      this.stockCountList = data.results.map((result) => {
        const stockSummary = result.items.reduce((previousValue, currentValue) => {
          let acc = { ...previousValue }

          if (!acc?.stockCount) {
            acc = {
              stockCount: 0,
              stockCountValue: 0,
              stockBc: 0,
              stockBcValue: 0
            }
          }

          acc.stockCount += currentValue.quantity
          acc.stockCountValue += currentValue.quantity ? currentValue.quantity * currentValue.price : 0

          acc.stockBc += currentValue.physicalQuantity
          acc.stockBcValue += currentValue.physicalQuantity ? currentValue.physicalQuantity * currentValue.price : 0

          return acc
        }, {})

        return {
          ...result,
          ...stockSummary
        }
      })
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
